import { Directive } from '@angular/core';
import { UntypedFormControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
  selector: '[appPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: PasswordDirective,
      multi: true
    }
  ]
})
export class PasswordDirective {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.validatePassword();
  }
  validatePassword(): ValidatorFn {
    return (control: UntypedFormControl) => {
      console.log(control)
      if (control.value != null) {
        console.log(control.value)
        if(control.value['customerGstNo']===control.value['customerConfirmGstNo']){
          return null;
        }else{
          return {
            validatePassword: { valid: false }
          };
        }
      } else {
        return null;
      }
    };
  }
  // validatePassword(): ValidatorFn {
  //   return (control: AbstractControl) => {
  //     let isValid = false;
  //     if (control && control instanceof FormGroup) {
  //       let group = control as FormGroup;
  //       if (group.controls['passwordA'] && group.controls['passwordB']) {
  //         isValid = group.controls['passwordA'].value == group.controls['passwordB'].value;
  //       }
  //     }
  //     if (isValid) {
  //       return null;
  //     } else {
  //       return { 'passwordCheck': 'failed' }
  //     }
  //   }
  // }
  validate(c: UntypedFormControl) {
    return this.validator(c);
  }
}

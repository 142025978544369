// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endPoint: 'https://cloudide.co.in/workspace/pithvcp/public/api/v1/',
  remoteAssetsEndpoint: 'https://cloudide.co.in/workspace/pithvcp/public/',
  remotePrintEndpoint: 'https://cloudide.co.in/workspace/pithvcp/public/',
  isProduction: false,
  storagekeys: {
    authtoken: 'AUTH_TOKEN',
    islogin: 'login_status',
    loginDetails: 'login_details',
    dayDetails: 'day_details',
    daySyncTime: 'day_sync_time',
    salaryPaymentOpenCash: "salary_payment_openingcash",
  },
  limits: {
    purchase: {
      supplierMaxBaleLimit: 200,
      supplierMaxHuskCount: 50000,
      maxHuskPerCft: 20,
      maxHeight: 15,
      maxCorrectionFactor: 10,
      maxHuskPerBale: 750,
      maxCftPerBale: 150,
    }
  },
  buildVersion: '220215-B002'
};
if (environment.isProduction) {
  // environment.endPoint = 'https://pithapp.nif.co.in/api/';
  environment.remoteAssetsEndpoint = 'https://cloudide.co.in/workspace/pithvcp/public/';
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

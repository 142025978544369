import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  login_status:boolean=false;
  permissions:any=[];
  actionAllowed=[];
  authStatus = new BehaviorSubject(false);
  constructor(private platform: Platform,private router: Router,
    private storage: Storage, private navcontroller: NavController, private loadingController: LoadingController) {
      this.platform.ready().then(()=>{
        this.initialize();
      })
     }
     canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<any> | Promise<any> {
      return new Promise<any>((resolve) => {
        resolve(this.login_status);
     });
    }
    getAuthStatus(){
      return this.authStatus.asObservable();
    }
    async initialize(){
      // var loading = await this.loadingController.create({
      //   message: 'App Loading...',
      //   spinner: 'bubbles'
      // });
      // await loading.present();
      this.storage.get(environment.storagekeys.islogin).then( data => {
        this.login_status= data;
        console.log(data, this.login_status);
        if(!this.login_status){
          this.navcontroller.navigateRoot(['/login']);
          this.authStatus.next(false);
        }else{
          this.storage.get(environment.storagekeys.loginDetails).then( data => {
            if(data!==null){
              this.permissions= data.permissions;
              this.authStatus.next(true);
              console.log(this.permissions);
              let arr=[];
              for(const [key, value] of Object.entries(this.permissions)) {
                arr.push(value)
              }
              arr.forEach(element => {
                element.forEach(ele => {
                  this.actionAllowed.push(ele)
                });
              });
              if(data.role ==="Driver"){
                if(this.router.url==='/login'||this.router.url==='/')
                this.navcontroller.navigateRoot(['/app/production/beater-buster']);
              }
              else{
                if(environment.isProduction){
                  if(this.router.url==='/login'||this.router.url==='/')
                  this.navcontroller.navigateRoot(['/app/dashboard']);
                }else{

                  if(this.router.url==='/login')
                  // if(this.router.url==='/login'||this.router.url==='/')
                  this.navcontroller.navigateRoot(['/app/dashboard']);
                }
              }
            }
          })
        }
        // loading.dismiss();
      });
    }
    async setLoginStatus(setloginstatus:boolean=false, loginDetails:any={}){
      await this.storage.set(environment.storagekeys.islogin,setloginstatus).then(s=>console.log(s));
      this.login_status = setloginstatus;
      await this.storage.set(environment.storagekeys.loginDetails,loginDetails).then(s=>console.log(s));
      this.permissions = loginDetails.permissions;
      if(setloginstatus)
        this.initialize()
    }
    checkAccess(module,action=null,isMulti=false){
      if(this.login_status)
        if(isMulti){
          let status=false;
          for (let i = 0; i < module.length; i++) {
            const element = module[i];
            if(this.permissions[element]&&this.permissions[element].length>0 &&status==false)
              status= true;
            if(i+1==module.length)
              return status;
          }
        }else{
          if(action===null)
            return (this.permissions[module]&&this.permissions[module].length>0)
          if(!this.permissions[module])
            return false;
          return (this.permissions[module].includes(action))
        }
    }
    checkAction(action){
      if(Array.isArray(action)){
        let status=false;
        for (let i = 0; i < action.length; i++) {
          const element = action[i];
          if(this.actionAllowed.includes(element)&&status==false)
            status= true;
          if(i+1==action.length)
            return status;
        }
      }else{
        // console.log(this.actionAllowed.includes(action))
        return (this.actionAllowed.includes(action))
      }
    }
    checkModuleAction(module,action){
      // console.log(module, action)
      if(this.login_status)
        if(Array.isArray(action)){
          let status=false;
          for (let i = 0; i < action.length; i++) {
            const element = action[i];
            if(this.permissions[module]&&this.permissions[module].includes(element)&&status==false)
              status= true;
            if(i+1==action.length)
              return status;
          }
        }else{
          if(!this.permissions[module])
            return false;
          return (this.permissions[module].includes(action))
        }
    }
    getModulePermission(module){
      if(!this.permissions[module])
        return [];
      return this.permissions[module];
    }
}
